import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Dropdown from "react-bootstrap/Dropdown";
import { Tooltip } from "react-tooltip";

import formatNumber from "../../helpers/formatting/formatNumber";
import convertToCamelCase from "../../helpers/formatting/convertToCamelCase";
import isEven from "../../helpers/formatting/isEven";
import {
    USER_TABLE_ITEM_HEIGHT,
    ASCENDING_SORT_ORDER,
    DESCENDING_SORT_ORDER,
    USER_TABLE_EMPTY_LINE_COUNT
} from "../../helpers/constants/constants";

const UsersTable = ({ data, isLoading }) => {
    const { t } = useTranslation(['common', 'users']);

    const listRef = useRef();
    const [sortedData, setSortedData] = useState(data);
    const [sortProperty, setSortProperty] = useState("logins");
    const [sortOrder, setSordOrder] = useState(DESCENDING_SORT_ORDER);
    const [showEmailsTooltip, setShowEmailsTooltip] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [itemDifference, setItemDifference] = useState(0);

    const changeSortOrder = (order, property) => {
        let filteredData = data;

        if (searchTerm) {
            var result = data.filter(function (value, i) {
                return (value.firstName.toLocaleLowerCase() + ' ' + value.lastName.toLocaleLowerCase()).includes(searchTerm.toLocaleLowerCase())
                    || (value.lastName.toLocaleLowerCase() + ' ' + value.firstName.toLocaleLowerCase()).includes(searchTerm.toLocaleLowerCase())
                    || value.email.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
            })

            filteredData = result;
        }

        const sortArray = type => {
            let sorted;
            const dataType = data[0][type];

            if (typeof dataType === 'string') {
                sorted = order === DESCENDING_SORT_ORDER ? [...filteredData].sort((a, b) => b[type].localeCompare(a[type])) : [...filteredData].sort((a, b) => a[type].localeCompare(b[type]));
            } else {
                sorted = order === DESCENDING_SORT_ORDER ? [...filteredData].sort((a, b) => b[type] - a[type]) : [...filteredData].sort((a, b) => a[type] - b[type]);
            }

            if (sorted.length === 0) setItemDifference(0);

            setSortedData(sorted);
        };

        setSordOrder(order);
        setSortProperty(property);

        if (data?.length) {
            sortArray(convertToCamelCase(property));
        }
    }

    useEffect(() => {
        if (data?.length)
            changeSortOrder(sortOrder, sortProperty)
        else
            setSortedData([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {

        if (data?.length)
            changeSortOrder(sortOrder, sortProperty);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    const onEmailHover = (e) => {
        setShowEmailsTooltip(e?.target?.children[0]?.offsetWidth < e?.target?.children[0]?.scrollWidth);
    }

    const checkHeight = () => {
        var totalHeight = sortedData?.length * USER_TABLE_ITEM_HEIGHT;

        const heightDifference = listRef?.current?.props?.height - totalHeight;
        if (heightDifference <= 0) {
            setItemDifference(0);
            return;
        }

        let missingItems = 0;
        if (heightDifference > 0) missingItems = Math.floor(heightDifference / USER_TABLE_ITEM_HEIGHT);

        if (missingItems > 0) {
            setSortedData(sortedData.concat(emptyUsers.slice(0, missingItems)));
            setItemDifference(missingItems);
        }
    }

    const UserRow = ({ index, style }) => (
        <div
            style={style}
            className={`row table-row border-bottom-grey-200 ps-3 ${sortedData[index]?.isDeleted ? 'c-grey-800' : 'c-dark-blue'}`}>
            <div className="col-6">
                <div className="row h-100">
                    <div className="col-3 d-flex align-items-center">
                        {sortedData[index]?.firstName}
                    </div>
                    <div className="col-4 d-flex align-items-center">
                        {sortedData[index]?.lastName}
                    </div>
                    <div className="col-5 d-flex align-items-center" onMouseEnter={onEmailHover}>
                        <span className={`d-block text-truncate tooltip-anchor-users-email-${index}`} >
                            {sortedData[index]?.email}
                        </span>
                        {showEmailsTooltip &&
                            <Tooltip anchorSelect={`.tooltip-anchor-users-email-${index}`} place="right">
                                {sortedData[index]?.email}
                            </Tooltip>
                        }
                    </div>
                </div>
            </div>

            <div className="col-6">
                <div className="row h-100">
                    <div className="col-3 d-flex align-items-center">
                        {formatNumber(sortedData[index]?.logins)}
                    </div>
                    <div className="col-3 d-flex align-items-center">
                        {formatNumber(sortedData[index]?.downloads)}
                    </div>
                    <div className="col-3 d-flex align-items-center">
                        {formatNumber(sortedData[index]?.shares)}
                    </div>
                    <div className="col-3 d-flex align-items-center">
                        {formatNumber(sortedData[index]?.uploads)}
                    </div>
                </div>
            </div>
        </div>
    )

    const SortIcon = ({ property }) =>
    (
        <>
            {property === sortProperty
                ? <img
                    alt="arrow-sort-desc"
                    style={{ height: 20 }}
                    className={`px-2 ${sortOrder === DESCENDING_SORT_ORDER ? 'rotate-180' : ''}`}
                    src={`${process.env.PUBLIC_URL}/images/icons/arrow-sort-up.svg`} />
                : <img
                    alt="arrow-sort"
                    style={{ height: 18 }}
                    className="px-2"
                    src={`${process.env.PUBLIC_URL}/images/icons/arrow-sort.svg`} />
            }
        </>
    );

    const ColumnHeader = ({ property, propertyType, col = 3 }) =>
    (
        <div
            className={`col-${col} table-header cursor-pointer no-selection ${sortProperty === property && sortedData.length > 0 ? 'fw-bold c-brand-dark-blue' : ''}`}>
            <Dropdown>
                <Dropdown.Toggle className="d-flex align-items-center">
                    <span className={`d-flex align-items-center text-truncate ${property === sortProperty && sortedData.length > 0 ? 'current' : ''}`}>
                        <span className="d-block text-truncate c-grey-800" title={t(property)}>
                            {t(property)}
                        </span>

                        <SortIcon property={property} />
                    </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={() => changeSortOrder(DESCENDING_SORT_ORDER, property)}
                        className={`text-truncate ${(sortOrder === DESCENDING_SORT_ORDER && sortProperty === property) ? 'active' : ''}`}>
                        <img
                            alt="arrow-sort-desc"
                            className="px-2 rotate-180"
                            src={`${process.env.PUBLIC_URL}/images/icons/arrow-sort-up.svg`} />
                        {propertyType === 'string' ? 'Z-A' : t('most_to_least')}
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => changeSortOrder(ASCENDING_SORT_ORDER, property)}
                        className={`text-truncate ${(sortOrder === ASCENDING_SORT_ORDER && sortProperty === property) ? 'active' : ''}`}>
                        <img
                            alt="arrow-sort-asc"
                            className="px-2"
                            src={`${process.env.PUBLIC_URL}/images/icons/arrow-sort-up.svg`} />
                        {propertyType === 'string' ? 'A-Z' : t('least_to_most')}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );

    const PlaceholderRow = ({ index }) =>
    (
        <div className="d-flex border-top-grey-200 text-sm py-2 ps-3">
            <div className="col-6 py-1">
                <div className="d-flex h-100">
                    <div className="placeholder-glow col-3 d-flex align-items-center">
                        <span className={`placeholder c-grey-200 border-radius w-${!isEven(index) ? '75' : '50'}`}>&nbsp;</span>
                    </div>
                    <div className="placeholder-glow col-4 d-flex align-items-center">
                        <span className={`placeholder c-grey-200 border-radius w-${!isEven(index) ? '75' : '50'}`}>&nbsp;</span>
                    </div>
                    <div className="placeholder-glow col-5 d-flex align-items-center">
                        <span className={`placeholder c-grey-200 border-radius w-${!isEven(index) ? '75' : '50'}`}>&nbsp;</span>
                    </div>
                </div>
            </div>
            <div className="col-6 py-1">
                <div className="d-flex h-100">
                    <div className="placeholder-glow col-3 d-flex align-items-center">
                        <span className={`placeholder c-grey-200 border-radius w-${!isEven(index) ? '75' : '50'}`}>&nbsp;</span>
                    </div>
                    <div className="placeholder-glow col-3 d-flex align-items-center">
                        <span className={`placeholder c-grey-200 border-radius w-${!isEven(index) ? '75' : '50'}`}>&nbsp;</span>
                    </div>
                    <div className="placeholder-glow col-3 d-flex align-items-center">
                        <span className={`placeholder c-grey-200 border-radius w-${!isEven(index) ? '75' : '50'}`}>&nbsp;</span>
                    </div>
                    <div className="placeholder-glow col-3 d-flex align-items-center">
                        <span className={`placeholder c-grey-200 border-radius w-${!isEven(index) ? '75' : '50'}`}>&nbsp;</span>
                    </div>
                </div>
            </div>
        </div>
    );

    const emptyUser = {
        downloads: null,
        id: null,
        firstName: null,
        lastName: null,
        uploads: null,
        shares: null,
        logins: null
    };

    const emptyUsers = [emptyUser, emptyUser, emptyUser, emptyUser, emptyUser, emptyUser, emptyUser, emptyUser, emptyUser, emptyUser, emptyUser, emptyUser];

    return (
        <>
            {
                !isLoading &&
                <>
                    <div className="d-flex flex-grow-1 flex-column">
                        <div className="row mb-3">
                            <div className="col-9">
                                {sortedData.length === 1 ? t('users:users_count_one', { count: sortedData.length - itemDifference }) : t('users:users_count', { count: sortedData.length - itemDifference })}
                            </div>
                            <div className="d-flex col-3">
                                <input
                                    type="text"
                                    className="search-input"
                                    value={searchTerm}
                                    name="search-input"
                                    placeholder={t('search_placeholder')}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    aria-label="search" />
                                {
                                    searchTerm &&
                                    <button type="button" className="btn bg-transparent p-0" style={{ marginLeft: "-25px", zIndex: 100 }}>
                                        <img
                                            alt="close"
                                            className="px-1"
                                            onClick={() => setSearchTerm('')}
                                            src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
                                    </button>
                                }
                            </div>
                        </div>


                        <div className="d-flex ps-3 py-2 bc-grey-200 border-radius" style={{ paddingRight: 10 }}>
                            <div className="col-6">
                                <div className="d-flex">
                                    <ColumnHeader property="first_name" propertyType="string" col={3} />
                                    <ColumnHeader property="last_name" propertyType="string" col={4} />
                                    <ColumnHeader property="email" propertyType="string" col={5} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-flex">
                                    <ColumnHeader property="logins" />
                                    <ColumnHeader property="downloads" />
                                    <ColumnHeader property="shares" />
                                    <ColumnHeader property="uploads" />
                                </div>
                            </div>
                        </div>
                        {
                            <div style={{ display: sortedData?.length > 0 ? 'flex' : 'none', flexGrow: 1 }}>
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            className="c-grey-800"
                                            height={height}
                                            ref={listRef}
                                            style={{ overflowX: 'hidden' }}
                                            itemCount={sortedData?.length}
                                            itemSize={USER_TABLE_ITEM_HEIGHT}
                                            onItemsRendered={() => checkHeight()}
                                            overscanCount={10}
                                            width={width}>
                                            {UserRow}
                                        </List>
                                    )}
                                </AutoSizer>
                            </div>
                        }

                        {sortedData?.length === 0 &&
                            <div
                                className="d-flex flex-column flex-grow-1 justify-content-between position-relative">
                                <div style={{ display: sortedData?.length === 0 ? 'flex' : 'none', flexGrow: 1 }}>
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                className="c-grey-800"
                                                style={{ overflow: 'hidden' }}
                                                height={height}
                                                itemCount={emptyUsers?.length}
                                                itemSize={USER_TABLE_ITEM_HEIGHT}
                                                width={width}>
                                                {UserRow}
                                            </List>
                                        )}
                                    </AutoSizer>
                                </div>
                                <div
                                    className="d-flex flex-column align-items-center justify-content-center position-absolute top-50 start-50 translate-middle">
                                    <img
                                        alt="not found"
                                        src={`${process.env.PUBLIC_URL}/images/errors/no-results.svg`} />
                                    <div className="c-dark-blue fw-semi-bold">{t('no_results')}</div>
                                    <div className="text-center w-75">
                                        <span className="c-dark-blue">
                                            {t('no_results_description')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </>
            }
            {
                isLoading &&
                <>
                    <div className="d-flex flex-grow-1 flex-column mt-3">
                        <div className="d-flex ps-3 pb-3">
                            <div className="col-6">
                                <div className="d-flex">
                                    <div className="placeholder-glow col-3">
                                        <span className="placeholder c-grey-200 border-radius w-75">&nbsp;</span>
                                    </div>
                                    <div className="placeholder-glow col-4">
                                        <span className="placeholder c-grey-200 border-radius w-75">&nbsp;</span>
                                    </div>
                                    <div className="placeholder-glow col-5">
                                        <span className="placeholder c-grey-200 border-radius w-75">&nbsp;</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="d-flex">
                                    <div className="placeholder-glow col-3">
                                        <span className="placeholder c-grey-200 border-radius w-75">&nbsp;</span>
                                    </div>
                                    <div className="placeholder-glow col-3">
                                        <span className="placeholder c-grey-200 border-radius w-75">&nbsp;</span>
                                    </div>
                                    <div className="placeholder-glow col-3">
                                        <span className="placeholder c-grey-200 border-radius w-75">&nbsp;</span>
                                    </div>
                                    <div className="placeholder-glow col-3">
                                        <span className="placeholder c-grey-200 border-radius w-75">&nbsp;</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexGrow: 1 }}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <List
                                        className="c-grey-800"
                                        style={{ overflow: 'hidden' }}
                                        height={height}
                                        itemCount={USER_TABLE_EMPTY_LINE_COUNT}
                                        itemSize={USER_TABLE_ITEM_HEIGHT}
                                        width={width}>
                                        {PlaceholderRow}
                                    </List>
                                )}
                            </AutoSizer>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default UsersTable;