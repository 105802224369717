import { useEffect, useState, useCallback, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";

import Tile from "../../../components/molecules/Tile";
import Tooltip from "../../../components/atoms/Tooltip";
import BarChart from "../../../components/organisms/BarChart";
import ExportFile from "../../../components/molecules/ExportFile";
import DateSelectDropdown from "../../../components/molecules/DateSelectDropdown";
import PieChartWithLegend from "../../../components/organisms/PieChartWithLegend";

import sum from "../../../helpers/arrays/sum";
import bytesToGb from "../../../helpers/formatting/bytesToGb";
import percentage from "../../../helpers/formatting/percentage";
import exportAsImage from "../../../helpers/export/exportAsImage";
import formatNumber from "../../../helpers/formatting/formatNumber";
import adjustPresent from "../../../helpers/formatting/adjustPresent";
import filterAssetTypes from "../../../helpers/arrays/filterAssetTypes";
import getAssetTypeColor from "../../../helpers/colors/getAssetTypeColor";
import formatBarCharAsset from "../../../helpers/formatting/formatBarCharAsset";
import filterAssetsByEventTypes from "../../../helpers/arrays/filterAssetsByEventTypes";
import {
    DESCENDING_SORT_ORDER,
    TENANT_ID_KEY,
    DAM_REPORTING_DATE_KEY,
    CALENDAR_OPTIONS,
    CUSTOM_TIME_RANGE_VALUE,
    EVENT_SHARE,
    EVENT_DOWNLOAD,
    EVENT_DOWNLOAD_FROM_SHARE,
    EVENT_SHARE_BY_EMAIL,
} from "../../../helpers/constants/constants";

import UsersService from "../../../services/users-service";
import AssetsService from "../../../services/assets-service";
import EventsService from "../../../services/events-service";

import { WarningContext } from "../../../contexts/WarningContext";
import { AssetStateContext } from "../../../contexts/AssetStateContextProvider";

import AssetsActivity from "./AssetsActivity";
import AssetsUploadChart from "./AssetsUploadChart";
import ActivityByAssetTypes from "./ActivityByAssetTypes";

const assetsService = AssetsService();
const usersService = UsersService();
const eventsService = EventsService();

const BarChartCOLORS = ['#1A359F', '#D65E35', '#3F7A1A'];

const DamLevelReporting = () => {
    const { t, i18n } = useTranslation(['dam', 'assets', 'common']);

    const [assetsCount, setAssetsCount] = useState(0);
    const [isAssetsCountLoading, setIsAssetsCountLoading] = useState(false);

    const [minDate, setMinDate] = useState(new Date());
    const [endRange, setEndRange] = useState();
    const [startRange, setStartRange] = useState();
    const [selectedOption, setSelectedOption] = useState();

    const [downloadsPerformance, setDownloadsPerformance] = useState([]);
    const [previewsPerformance, setPreviewsPerformance] = useState([])
    const [sharesPerformance, setSharesPerformance] = useState([]);
    const [performanceAssetTypes, setPerformanceAssetTypes] = useState({});

    const [usersCount, setUsersCount] = useState(0);
    const [isUsersCountLoading, setIsUsersCountLoading] = useState(false);

    const [sharesCount, setSharesCount] = useState(0);
    const [isSharesCountLoading, setIsSharesCountLoading] = useState(false);

    const [downloadsCount, setDownloadsCount] = useState(0);
    const [isDownloadsCountLoading, setIsDownloadsCountLoading] = useState(false);

    const [assetsSize, setAssetsSize] = useState(0);
    const [isAssetsSizeLoading, setIsAssetsSizeLoading] = useState(false);

    const [assetsTypesCount, setAssetsTypesCount] = useState([]);
    const [isAssetsTypesCountLoading, setIsAssetsTypesCountLoading] = useState(false);

    const [assetsActivities, setAssetsActivities] = useState([]);
    const [isAssetsActivitiesLoading, setIsAssetsActivitiesLoading] = useState(false);

    const [assetsActivitiesByType, setAssetsActivitiesByType] = useState([]);
    const [isAssetsActivitiesByTypeLoading, setIsAssetsActivitiesByTypeLoading] = useState(false);

    const [downloadsSortOrder, setDownloadsSortOrder] = useState(DESCENDING_SORT_ORDER);
    const [sharesSortOrder, setSharesSortOrder] = useState(DESCENDING_SORT_ORDER);
    const [previewsSortOrder, setPreviewsSortOrder] = useState(DESCENDING_SORT_ORDER);

    const [uploadsProgress, setUploadsProgress] = useState([]);
    const [uploadsProgressIsLoading, setUploadsProgressIsLoading] = useState(true);

    const [showError, setShowError] = useState(false);

    const [assetTypes, setAssetTypes] = useState([]);
    const [downloadsType, setDownloadsAssetType] = useState([]);
    const [sharesType, setSharesAssetType] = useState([]);
    const [previewsType, setPreviewsAssetType] = useState([]);

    const [, setState] = useContext(WarningContext);
    const [showInactiveAssets, ,] = useContext(AssetStateContext);

    const assetCountRef = useRef();
    const assetUploadRef = useRef();
    const activityByAssetTypesRef = useRef();

    // workaround to get tenant from localstorage until auth is implemented
    let tenant = window.localStorage.getItem(TENANT_ID_KEY);

    const handleLanguageChanged = useCallback(() => {
        setAssetsTypesCount(current =>
            current?.map(obj => {
                return { ...obj, label: t(`assets:${obj?.id?.toLowerCase()}`) }
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        i18n.on('languageChanged', handleLanguageChanged);
        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
    }, [handleLanguageChanged, i18n]);

    useEffect(() => {
        if (!tenant)
            return;

        assetsService.init();
        usersService.init();
        eventsService.init();
        let prevOptions = window.localStorage.getItem(DAM_REPORTING_DATE_KEY);
        if (prevOptions) {
            prevOptions = JSON.parse(prevOptions);
            setEndRange(new Date(prevOptions?.to));
            setStartRange(new Date(prevOptions?.from));
            setSelectedOption(prevOptions?.option);
        } else
            setSelectedOption(CALENDAR_OPTIONS[2]);

        setIsAssetsCountLoading(true);
        assetsService
            .getCount(tenant)
            .then((response => setAssetsCount(response.data)))
            .finally(() => setIsAssetsCountLoading(false));

        setIsUsersCountLoading(true);
        usersService
            .getCount(tenant)
            .then((response => setUsersCount(response.data)))
            .finally(() => setIsUsersCountLoading(false));

        setIsAssetsSizeLoading(true);
        assetsService
            .getSize(tenant)
            .then((response => setAssetsSize(response.data)))
            .finally(() => setIsAssetsSizeLoading(false));

        eventsService
            .getStartDate(tenant)
            .then((response => {
                let minDate = new Date(response.data);
                setMinDate(new Date(new Date(minDate).setDate(minDate.getDate() - 1)));
            }));

        setIsAssetsTypesCountLoading(true);
        assetsService
            .getCountByTypes(tenant)
            .then((response => {
                if (response.data) {
                    const totalCount = sum(response.data, 'count');
                    setAssetsTypesCount(
                        response.data
                            .map((asset => (
                                {
                                    id: asset.type,
                                    label: t(`assets:${asset.type.toLowerCase()}`),
                                    value: adjustPresent(asset.count, totalCount),
                                    realValue: asset.count,
                                    color: getAssetTypeColor(asset.type),
                                    percentage: percentage(asset.count, totalCount)
                                })))
                    );
                }
            }))
            .finally(() => setIsAssetsTypesCountLoading(false));

        assetsService
            .getTypes(tenant)
            .then((response => {
                if (!response.data) return;
                let mappedTypes = response.data
                    .map((asset => (
                        {
                            id: asset.id,
                            selector: asset.key.toLowerCase(),
                            name: t(`assets:${asset.key.toLowerCase()}`),
                        })));
                mappedTypes
                    .unshift({
                        id: null,
                        selector: 'all',
                        name: t(`assets:all`),
                    });
                setAssetTypes(mappedTypes);
                setDownloadsAssetType(mappedTypes[0]);
                setSharesAssetType(mappedTypes[0]);
                setPreviewsAssetType(mappedTypes[0]);
            }));

        return () => {
            assetsService.dispose();
            usersService.dispose();
            eventsService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setIsDownloadsCountLoading(true);

        setIsSharesCountLoading(true);
        assetsService
            .getEventsCount(tenant, [EVENT_SHARE, EVENT_SHARE_BY_EMAIL], showInactiveAssets)
            .then((response => setSharesCount(response.data)))
            .finally(() => setIsSharesCountLoading(false));

        assetsService
            .getEventsCount(tenant, [EVENT_DOWNLOAD, EVENT_DOWNLOAD_FROM_SHARE], showInactiveAssets)
            .then((response => setDownloadsCount(response.data)))
            .finally(() => setIsDownloadsCountLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showInactiveAssets])

    useEffect(() => {
        if (!endRange || !startRange) return;

        setAssetsActivitiesByType({});
        setIsAssetsActivitiesByTypeLoading(true);
        eventsService
            .getCountByAssetTypes(tenant, startRange, endRange, showInactiveAssets)
            .then((response) => {
                setAssetsActivitiesByType(response.data);
            })
            .finally(() => {
                setIsAssetsActivitiesByTypeLoading(false);
            });

        setIsAssetsActivitiesLoading(true);
        assetsService
            .getActivity(tenant, startRange, endRange, showInactiveAssets)
            .then((response) => {
                if (!response?.data || response.data.length === 0) {
                    setAssetsActivities([]);
                    setDownloadsPerformance([]);
                    setSharesPerformance([]);
                    setPreviewsPerformance([]);
                    return;
                };

                setAssetsActivities(response.data);
                const downloads = response.data.map(d => formatBarCharAsset(d.id, d.type, d.downloads, d.name, d.isDeleted));
                const shares = response.data.map(d => formatBarCharAsset(d.id, d.type, d.shares, d.name, d.isDeleted));
                const previews = response.data.map(d => formatBarCharAsset(d.id, d.type, d.previews, d.name, d.isDeleted));
                const filteredDownloads = filterAssetsByEventTypes(downloads, downloadsType, downloadsSortOrder);
                const filteredShares = filterAssetsByEventTypes(shares, sharesType, sharesSortOrder);
                const filteredPreviews = filterAssetsByEventTypes(previews, previewsType, previewsSortOrder);
                setDownloadsPerformance(filteredDownloads);
                setSharesPerformance(filteredShares);
                setPreviewsPerformance(filteredPreviews);
            })
            .finally(() => {
                setIsAssetsActivitiesLoading(false);
            });

        window.localStorage.setItem(DAM_REPORTING_DATE_KEY,
            JSON.stringify({
                from: startRange, to: endRange, option: selectedOption
            }));

        setUploadsProgressIsLoading(true);
        eventsService
            .getUploads(tenant, startRange, endRange, showInactiveAssets)
            .then((response => setUploadsProgress(response.data)))
            .finally(() => setUploadsProgressIsLoading(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startRange, endRange, showInactiveAssets])

    useEffect(() => {
        if (!assetTypes?.length || !assetsActivities?.length) return;
        const downloads = assetsActivities.map(d => formatBarCharAsset(d.id, d.type, d.downloads, d.name, d.isDeleted));
        const shares = assetsActivities.map(d => formatBarCharAsset(d.id, d.type, d.shares, d.name, d.isDeleted));
        const previews = assetsActivities.map(d => formatBarCharAsset(d.id, d.type, d.previews, d.name, d.isDeleted));
        const performanceAssetTypes = {
            downloadsTypes: filterAssetTypes(downloads, assetTypes),
            sharesTypes: filterAssetTypes(shares, assetTypes),
            previewsTypes: filterAssetTypes(previews, assetTypes)
        };
        setPerformanceAssetTypes(performanceAssetTypes);
    }, [assetTypes, assetsActivities]);

    useEffect(() => {
        const downloads = assetsActivities.map(d => formatBarCharAsset(d.id, d.type, d.downloads, d.name, d.isDeleted));
        setDownloadsPerformance(filterAssetsByEventTypes(downloads, downloadsType, downloadsSortOrder));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadsSortOrder, downloadsType]);

    useEffect(() => {
        const previews = assetsActivities.map(d => formatBarCharAsset(d.id, d.type, d.previews, d.name, d.isDeleted));
        setPreviewsPerformance(filterAssetsByEventTypes(previews, previewsType, previewsSortOrder));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previewsSortOrder, previewsType]);

    useEffect(() => {
        const shares = assetsActivities.map(d => formatBarCharAsset(d.id, d.type, d.shares, d.name, d.isDeleted));
        setSharesPerformance(filterAssetsByEventTypes(shares, sharesType, sharesSortOrder));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharesSortOrder, sharesType]);

    const closeError = () => setShowError(false);

    useEffect(() => {
        setState({
            message: t('common:file_export_error'),
            bc_color: '#F8D7DA',
            show: showError,
            close: closeError
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showError]);

    useEffect(() => {
        let prevOptions = window.localStorage.getItem(DAM_REPORTING_DATE_KEY);
        if (prevOptions) {
            prevOptions = JSON.parse(prevOptions);

            if (new Date(prevOptions?.from).toDateString() === startRange?.toDateString()
                && new Date(prevOptions?.to).toDateString() === endRange?.toDateString()
                && selectedOption?.value === CUSTOM_TIME_RANGE_VALUE
                && prevOptions?.option.value !== CUSTOM_TIME_RANGE_VALUE) {
                setEndRange();
                setStartRange();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption]);

    const exportAssetCount = (format) => {
        exportAsImage(assetCountRef, format, t('assets_types_count'));
    }

    const exportAssetsUpload = (format) => {
        exportAsImage(assetUploadRef, format, `${t('assets_upload')} ${t('date:full_date', { date: new Date(startRange) })} - ${t('date:full_date', { date: new Date(endRange) })}`);
    }

    const exportActivityByAssetTypes = (format) => {
        exportAsImage(activityByAssetTypesRef, format, `${t('assets_activity_by_type')} ${t('date:full_date', { date: new Date(startRange) })} - ${t('date:full_date', { date: new Date(endRange) })}`);
    }

    return (
        <>
            <div className="mb-4 py-2">
                <h6 className="fw-semi-bold no-line-height">{t('dam_level')}</h6>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="row mb-4">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <Tile
                                name="assets-count"
                                title={t('assets_count')}
                                value={formatNumber(assetsCount)}
                                description={t('assets_count_description')}
                                isLoading={isAssetsCountLoading} />
                        </div>
                        <div className="col-12 col-md-6">
                            <Tile
                                name="users-count"
                                title={t('common:users')}
                                value={formatNumber(usersCount)}
                                description={t('users_count_description')}
                                isLoading={isUsersCountLoading} />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <Tile
                                name="shares-count"
                                title={t('common:shares')}
                                value={formatNumber(sharesCount)}
                                description={t('shares_count_description')}
                                isLoading={isSharesCountLoading} />
                        </div>
                        <div className="col-12 col-md-6">
                            <Tile
                                name="downloads-count"
                                title={t('common:downloads')}
                                value={formatNumber(downloadsCount)}
                                description={t('downloads_count_description')}
                                isLoading={isDownloadsCountLoading} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 mb-4 mb-lg-0">
                            <Tile
                                name="assets-size"
                                title={t('assets_size')}
                                value={bytesToGb(assetsSize)}
                                description={t('assets_size_description')}
                                isLoading={isAssetsSizeLoading} />
                        </div>
                    </div>
                </div>
                <div ref={assetCountRef} className="col-12 col-lg-6">
                    <div className="d-flex flex-column flex-grow-1 bc-white p-4 pt-3 box-shadow border-radius exportable">
                        {!isAssetsTypesCountLoading &&
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <div>
                                    <span className="c-grey-800 text-truncate no-line-height">{t('assets_types_count')}</span>
                                    <Tooltip
                                        anchor={`tooltip-anchor-assets-types-count`}
                                        description={t('assets_types_count_description')} />
                                </div>
                                {assetsTypesCount && assetsTypesCount?.length > 0 &&
                                    <div data-html2canvas-ignore>
                                        <ExportFile
                                            exportFile={exportAssetCount} />
                                    </div>
                                }
                            </div>
                        }
                        {isAssetsTypesCountLoading &&
                            <div className="placeholder-glow mb-3">
                                <span className="placeholder w-25 c-grey-200 border-radius">&nbsp;</span>
                            </div>
                        }
                        <PieChartWithLegend
                            data={assetsTypesCount}
                            emptyStatePlaceholder="No data"
                            isLoading={isAssetsTypesCountLoading} />
                    </div>
                </div>
                <div className="col-12 py-4">
                    <DateSelectDropdown
                        minDate={minDate}
                        setEndRange={setEndRange}
                        setStartRange={setStartRange}
                        header={t('common:asset_level')}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        from={startRange}
                        to={endRange} />
                </div>
                <div ref={activityByAssetTypesRef} className="col-12">
                    <ActivityByAssetTypes
                        activities={assetsActivitiesByType}
                        isLoading={isAssetsActivitiesByTypeLoading}
                        from={startRange}
                        to={endRange}
                        exportChart={exportActivityByAssetTypes} />
                </div>
                <div className="col-12">
                    <BarChart
                        data={downloadsPerformance}
                        color={BarChartCOLORS[0]}
                        headerText={t("downloads_performance")}
                        tooltipText={t("downloads_performance_tooltip")}
                        emptyHeader={t("no_downloads")}
                        emptyMessage={t("no_data_message")}
                        emptyImg={`${process.env.PUBLIC_URL}/images/empty/downloads-performance.svg`}
                        selector={"downloads_performance"}
                        isLoading={isAssetsActivitiesLoading}
                        sortOrder={downloadsSortOrder}
                        setSortOrder={setDownloadsSortOrder}
                        assetTypes={performanceAssetTypes.downloadsTypes}
                        selectedAssetType={downloadsType}
                        setAssetType={setDownloadsAssetType}
                        from={startRange}
                        to={endRange}
                        selectedOption={selectedOption}
                    />
                </div>
                <div className="col-12">
                    <BarChart
                        data={sharesPerformance}
                        color={BarChartCOLORS[1]}
                        headerText={t("shares_performance")}
                        tooltipText={t("shares_performance_tooltip")}
                        emptyHeader={t("no_shares")}
                        emptyMessage={t("no_data_message")}
                        emptyImg={`${process.env.PUBLIC_URL}/images/empty/shares-performance.svg`}
                        selector={"shares_performance"}
                        isLoading={isAssetsActivitiesLoading}
                        sortOrder={sharesSortOrder}
                        setSortOrder={setSharesSortOrder}
                        assetTypes={performanceAssetTypes.sharesTypes}
                        selectedAssetType={sharesType}
                        setAssetType={setSharesAssetType}
                        from={startRange}
                        to={endRange}
                        selectedOption={selectedOption}
                    />
                </div>
                <div className="col-12">
                    <BarChart
                        data={previewsPerformance}
                        color={BarChartCOLORS[2]}
                        headerText={t("previews_performance")}
                        tooltipText={t("previews_performance_tooltip")}
                        emptyHeader={t("no_previews")}
                        emptyMessage={t("no_data_message")}
                        emptyImg={`${process.env.PUBLIC_URL}/images/empty/previews-performance.svg`}
                        selector={"previews_performance"}
                        isLoading={isAssetsActivitiesLoading}
                        sortOrder={previewsSortOrder}
                        setSortOrder={setPreviewsSortOrder}
                        assetTypes={performanceAssetTypes.previewsTypes}
                        selectedAssetType={previewsType}
                        setAssetType={setPreviewsAssetType}
                        from={startRange}
                        to={endRange}
                        selectedOption={selectedOption}
                    />
                </div>
                <div ref={assetUploadRef} className="col-12">
                    <AssetsUploadChart
                        data={uploadsProgress}
                        isLoading={uploadsProgressIsLoading}
                        exportChart={exportAssetsUpload}
                    />
                </div>
                <div className="col-12">
                    <AssetsActivity
                        activities={assetsActivities}
                        isLoading={isAssetsActivitiesLoading}
                        from={startRange}
                        to={endRange}
                        selectedOption={selectedOption}
                    />
                </div>
            </div>
        </>);
}

export default DamLevelReporting;