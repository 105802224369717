import axios from 'axios';

import {
    ACCESS_TOKEN_KEY,
    REDIRECT_TOKEN_KEY,
    UNAUTHORIZED_ROUTE_PATH,
    SERVER_ERROR_ROUTE_PATH,
    FORBIDDEN_ROUTE_PATH,
    SESSION_EXPIRED_ROUTE_PATH,
    NOT_FOUND_ROUTE_PATH,
    UNAUTHORIZED_ROUTE_ERROR_CODE,
    FORBIDDEN_ERROR_CODE,
    NOT_FOUND_ERROR_CODE,
    SESSION_EXPIRED_ERROR_CODE,
    SERVER_ERROR_CODE
} from "../helpers/constants/constants";

const AuthInterceptor = () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    axios.interceptors.request.use(
        request => {
            let token = window.localStorage.getItem(ACCESS_TOKEN_KEY) || window.localStorage.getItem(REDIRECT_TOKEN_KEY);

            if (!token) {
                return Promise.reject();
            }

            const isApiUrl = request.url.startsWith(process.env.REACT_APP_API_URL);
            const isDefaultRelativeApiUrl = request.url.startsWith('/');
            if (token && (isApiUrl || isDefaultRelativeApiUrl))
                request.headers.Authorization = "Bearer " + token;
            return request;
        },
        error => Promise.reject(error)
    );

    axios.interceptors.response.use(
        response => response,
        async error => {
            if (!error || !error.response || !error.response.status || !error.response.request)
                return Promise.reject(error);
            const isApiUrl = error.response.request.responseURL?.startsWith(process.env.REACT_APP_API_URL) ?? false;
            const isUnauthorized = error.response.status === UNAUTHORIZED_ROUTE_ERROR_CODE;
            const isForbidden = error.response.status === FORBIDDEN_ERROR_CODE;
            const isNotFound = error.response.status === NOT_FOUND_ERROR_CODE;
            const isExpired = error.response.status === SESSION_EXPIRED_ERROR_CODE;

            if (isApiUrl && error.response.status >= SERVER_ERROR_CODE) window.location.href = SERVER_ERROR_ROUTE_PATH;
            if (isApiUrl && isExpired) window.location.href = SESSION_EXPIRED_ROUTE_PATH;
            if (isApiUrl && isNotFound) window.location.href = NOT_FOUND_ROUTE_PATH;
            if (isApiUrl && isForbidden) window.location.href = FORBIDDEN_ROUTE_PATH;
            if (isApiUrl && isUnauthorized) window.location.href = UNAUTHORIZED_ROUTE_PATH;
            return Promise.reject(error);
        });
}

export default AuthInterceptor;