import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Dropdown } from "react-bootstrap";

import isEven from "../../helpers/formatting/isEven";
import formatNumber from "../../helpers/formatting/formatNumber";
import {
    USER_TABLE_ITEM_HEIGHT_MOBILE,
    ASCENDING_SORT_ORDER,
    DESCENDING_SORT_ORDER
} from "../../helpers/constants/constants";


const UsersTableMobile = ({ data, isLoading }) => {
    const { t } = useTranslation('common');

    const listRef = useRef();
    const [sortedData, setSortedData] = useState(data);
    const [sortProperty, setSortProperty] = useState("logins");
    const [sortOrder, setSortOrder] = useState(DESCENDING_SORT_ORDER);

    const [searchTerm, setSearchTerm] = useState('');

    const changeSortOrder = (order, property) => {
        let filteredData = data;

        if (searchTerm) {
            var result = data.filter(function (value, i) {
                return (value.firstName.toLocaleLowerCase() + ' ' + value.lastName.toLocaleLowerCase()).includes(searchTerm.toLocaleLowerCase())
                    || (value.lastName.toLocaleLowerCase() + ' ' + value.firstName.toLocaleLowerCase()).includes(searchTerm.toLocaleLowerCase())
                    || value.email.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
            })

            filteredData = result;
        }

        const sortArray = type => {
            let sorted = order === DESCENDING_SORT_ORDER ? [...filteredData].sort((a, b) => b[type] - a[type]) : [...filteredData].sort((a, b) => a[type] - b[type]);

            setSortedData(sorted);
        };

        setSortOrder(order);
        setSortProperty(property);

        if (data?.length) {
            sortArray(property);
        }
    }

    useEffect(() => {
        if (data?.length)
            changeSortOrder(sortOrder, sortProperty)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (data?.length)
            changeSortOrder(sortOrder, sortProperty);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    const checkHeight = () => {
        var totalHeight = sortedData?.length * USER_TABLE_ITEM_HEIGHT_MOBILE;

        const heightDifference = listRef?.current?.props?.height - totalHeight;

        if (heightDifference > 0) {
            setSortedData(sortedData.concat(emptyUsers.slice(0, Math.floor(heightDifference / USER_TABLE_ITEM_HEIGHT_MOBILE))));
        }
    }

    useEffect(() => {
        var totalHeight = sortedData?.length * USER_TABLE_ITEM_HEIGHT_MOBILE;

        const heightDifference = listRef?.current?.props?.height - totalHeight;

        if (heightDifference > 0) {
            setSortedData(sortedData.concat(emptyUsers.slice(0, Math.floor(heightDifference / USER_TABLE_ITEM_HEIGHT_MOBILE))));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listRef.current])

    const UserRow = ({ index, style }) => (
        <div
            style={style}
            className={`row table-row border-top-grey-200 ${sortedData[index]?.isDeleted ? 'c-grey-800' : 'c-dark-blue'}`}>
            <div className="col-8 d-flex flex-column justify-content-center">
                <span className="d-block text-truncate ps-3">{sortedData[index]?.firstName}&nbsp;{sortedData[index]?.lastName}</span>
                <span className="d-block text-truncate ps-3">{sortedData[index]?.email}</span>
            </div>
            <div className="d-flex align-items-center justify-content-end col-4">
                {sortedData.length && index >= 0 ? formatNumber(sortedData[index][sortProperty]) : ''}
            </div>
        </div>
    )

    const SortIcon = ({ property }) =>
    (
        <>
            <img
                alt="arrow-sort-desc"
                style={{ height: 18 }}
                className={`ps-1 ${sortOrder === ASCENDING_SORT_ORDER ? 'rotate-180' : 'rotate-back'}`}
                src={`${process.env.PUBLIC_URL}/images/icons/arrow-sort-desc.svg`} />
        </>
    );

    const ColumnHeader = ({ property }) =>
    (
        <div className="d-flex py-2" style={{ paddingRight: 10 }}>
            <div className="col-8 c-grey-800">
                <span className="ps-3">{t("user")}</span>
            </div>
            <div className="col-4 column-header">
                <Dropdown className="w-100">
                    <Dropdown.Toggle className="d-flex align-items-center justify-content-end pe-2">
                        <span className="current">
                            <SortIcon property={property} />
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => changeSortOrder(DESCENDING_SORT_ORDER, sortProperty)}
                            className={`${(sortOrder === DESCENDING_SORT_ORDER) ? 'active' : ''}`}>
                            <img
                                alt="arrow-sort-desc"
                                className={`pe-1 rotate-180`}
                                src={`${process.env.PUBLIC_URL}/images/icons/arrow-sort-up.svg`} />
                            {t('most_to_least')}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => changeSortOrder(ASCENDING_SORT_ORDER, sortProperty)}
                            className={`${(sortOrder === ASCENDING_SORT_ORDER) ? 'active' : ''}`}>
                            <img
                                alt="arrow-sort-asc"
                                className={`pe-1`}
                                src={`${process.env.PUBLIC_URL}/images/icons/arrow-sort-up.svg`} />
                            {t('least_to_most')}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );

    const PlaceholderRow = ({ index }) =>
    (
        <div className="row border-top-grey-200 text-sm py-2">
            <div className="col-8 d-flex flex-column justify-content-center">
                <div className="placeholder-glow pb-2 ps-3">
                    <span style={{ lineHeight: 0 }} className={`placeholder c-grey-200 ps-3 border-radius w-25`}>&nbsp;</span>
                    &nbsp;
                    <span style={{ lineHeight: 0 }} className={`placeholder c-grey-200 ps-3 border-radius w-25`}>&nbsp;</span>
                </div>

                <div className="placeholder-glow ps-3">
                    <span style={{ lineHeight: 0 }} className={`d-block placeholder c-grey-200 ps-3 border-radius w-75`}>&nbsp;</span>
                </div>
            </div>
            <div className="col-4">
                <div className="row h-100">
                    <div className="placeholder-glow col-12 d-flex align-items-center">
                        <span className={`placeholder c-grey-200 border-radius w-${!isEven(index) ? '75' : '50'}`}>&nbsp;</span>
                    </div>
                </div>
            </div>
        </div>
    );

    const EmptyState = () =>
    (
        <>
            {sortedData?.length === 0 && !isLoading &&
                <div className="d-flex flex-column flex-grow-1 justify-content-between px-3 position-relative">
                    <div style={{ display: 'flex', flexGrow: 1 }}>
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    className="c-grey-800"
                                    style={{ overflow: 'hidden' }}
                                    height={height}
                                    itemCount={emptyUsers?.length}
                                    itemSize={46}
                                    width={width}>
                                    {UserRow}
                                </List>
                            )}
                        </AutoSizer>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center position-absolute top-50 start-50 translate-middle">
                        <img
                            alt="not found"
                            src={`${process.env.PUBLIC_URL}/images/errors/no-results.svg`} />
                        <div className="c-dark-blue text-center fw-semi-bold">{t('no_results')}</div>
                        <div className="text-center w-75">
                            <span className="c-dark-blue">
                                {t('no_results_description')}
                            </span>
                        </div>
                    </div>
                </div>
            }
        </>
    );

    const UserTab = ({ eventType }) => (
        <>
            <div className="d-flex flex-column flex-grow-1" style={{ height: '325px', position: 'relative' }}>
                <ColumnHeader property={eventType} />
                {sortedData?.length > 0
                    ? <div style={{ display: 'flex', flexGrow: 1 }}>
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    className="c-grey-800 fade-in"
                                    height={height}
                                    itemCount={sortedData?.length}
                                    ref={listRef}
                                    itemSize={USER_TABLE_ITEM_HEIGHT_MOBILE}
                                    style={{ overflowX: 'hidden' }}
                                    onItemsRendered={() => checkHeight()}
                                    width={width}>
                                    {UserRow}
                                </List>
                            )}
                        </AutoSizer>
                    </div>
                    : <EmptyState asset={eventType} />
                }
            </div>

        </>
    );

    const emptyUser = {
        downloads: null,
        id: null,
        firstName: null,
        lastName: null,
        uploads: null,
        shares: null,
        logins: null
    };

    const emptyUsers = [emptyUser, emptyUser, emptyUser, emptyUser, emptyUser, emptyUser, emptyUser, emptyUser, emptyUser, emptyUser, emptyUser, emptyUser];

    return (
        <>
            {
                !isLoading &&
                <>
                    <div className="d-flex flex-column flex-grow-1">
                        <div className="row mb-2">
                            <div className="col-12 mb-2 c-black-100">
                                {sortedData.length === 1 ? t('users:users_count_one', { count: sortedData.length }) : t('users:users_count', { count: sortedData.length })}
                            </div>
                            <div className="d-flex col-12">
                                <input
                                    type="text"
                                    className="search-input"
                                    value={searchTerm}
                                    name="search-input"
                                    placeholder={t('search_placeholder')}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    aria-label="search" />
                                {
                                    searchTerm &&
                                    <button type="button" className="btn bg-transparent p-0" style={{ marginLeft: "-25px", zIndex: 100 }}>
                                        <img
                                            alt="close"
                                            className="px-1"
                                            onClick={() => setSearchTerm('')}
                                            src={`${process.env.PUBLIC_URL}/images/icons/x.svg`} />
                                    </button>
                                }
                            </div>
                        </div>
                        <div className="d-flex py-2 mb-2" style={{ paddingRight: 10 }}>
                            <div className="col-8">
                                {t('show_activity')}
                            </div>
                            <div className="col-4 table-header">
                                <Dropdown className="w-100">
                                    <Dropdown.Toggle className="d-flex align-items-center justify-content-end">
                                        <span className="bc-grey-200 border-radius ps-2">
                                            <span className="fw-bold text-truncate">
                                                {t(sortProperty)}
                                            </span>
                                            <img
                                                alt="arrow-down"
                                                src={`${process.env.PUBLIC_URL}/images/icons/arrow-down.svg`} />
                                        </span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="px-2">
                                        <Dropdown.Item
                                            onClick={() => changeSortOrder(sortOrder, 'logins')}
                                            className={`${(sortProperty === 'logins') ? 'active' : ''}`}>
                                            <div className="d-flex justify-content-between">
                                                <span className="text-truncate">{t('logins')}</span>

                                                {
                                                    sortProperty === 'logins' &&
                                                    <img
                                                        className="ps-1"
                                                        alt="check"
                                                        src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                                }
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => { changeSortOrder(sortOrder, 'downloads') }}
                                            className={`${(sortProperty === 'downloads') ? 'active' : ''}`}>
                                            <div className="d-flex justify-content-between">
                                                <span className="text-truncate">{t('downloads')}</span>
                                                {
                                                    sortProperty === 'downloads' &&
                                                    <img
                                                        className="ps-1"
                                                        alt="check"
                                                        src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                                }
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => { changeSortOrder(sortOrder, 'shares') }}
                                            className={`${(sortProperty === 'shares') ? 'active' : ''}`}>
                                            <div className="d-flex justify-content-between">
                                                <span className="text-truncate">{t('shares')}</span>

                                                {
                                                    sortProperty === 'shares' &&
                                                    <img
                                                        className="ps-1"
                                                        alt="check"
                                                        src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                                }
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => { changeSortOrder(sortOrder, 'uploads') }}
                                            className={`${(sortProperty === 'uploads') ? 'active' : ''}`}>
                                            <div className="d-flex justify-content-between">
                                                <span className="text-truncate">{t('uploads')}</span>

                                                {
                                                    sortProperty === 'uploads' &&
                                                    <img
                                                        className="ps-1"
                                                        alt="check"
                                                        src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                                }
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <UserTab eventType="logins" />
                    </div>
                </>
            }
            {
                isLoading &&
                <>
                    <div className="d-flex flex-column flex-grow-1">
                        <div className="mb-2">
                            <div className="col-md-9 col-lg-7 col-xl-6">
                                <div className="row">
                                    <div className="d-flex placeholder-glow col-12">
                                        <span className="placeholder c-grey-200 border-radius w-75">&nbsp;</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex placeholder-glow col-12  mb-2">
                            <div className="placeholder w-100 c-grey-200 rounded" style={{ height: 36.45 }}>
                            </div>
                        </div>

                        <div className="row text-sm py-2 mb-2">
                            <div className="col-3 d-flex flex-column justify-content-center">
                                <div className="placeholder-glow">
                                    <span className={`placeholder c-grey-200 ps-3 border-radius w-100`}>&nbsp;</span>
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="row h-100">
                                    <div className="placeholder-glow col-12 d-flex justify-content-end align-items-center">
                                        <span className={`placeholder c-grey-200 border-radius w-75`}>&nbsp;</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row text-sm py-2">
                            <div className="col-3 d-flex flex-column justify-content-center">
                                <div className="placeholder-glow">
                                    <span className={`placeholder c-grey-200 ps-3 border-radius w-100`}>&nbsp;</span>
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="row h-100">
                                    <div className="placeholder-glow col-12 d-flex justify-content-end align-items-center">
                                        <span className={`placeholder c-grey-200 border-radius w-50`}>&nbsp;</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: '325px', position: 'relative' }}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <List
                                        className="c-grey-800"
                                        style={{ overflow: 'hidden' }}
                                        height={height}
                                        itemCount={emptyUsers?.length}
                                        itemSize={USER_TABLE_ITEM_HEIGHT_MOBILE}
                                        width={width}>
                                        {PlaceholderRow}
                                    </List>
                                )}
                            </AutoSizer>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default UsersTableMobile;